<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Výrobci - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

        <a-form-item
          label="Popisek"
          :validate-status="error('mle_description') ? 'error' : ''"
          :help="error('mle_description') || ''"
        >
          <a-input
            :autosize="{ minRows: 4, maxRows: 10 }"
            type="textarea" placeholder="Popisek" v-decorator="['mle_description']"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item
          label="Název"
          :validate-status="error('mur_name') ? 'error' : ''"
          :help="error('mur_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['mur_name', {rules: [{max: 100, message: 'Název nemůže být delší než 100 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          label="URL"
          :validate-status="error('mur_url') ? 'error' : ''"
          :help="error('mur_url') || ''"
        >
          <a-input placeholder="URL" v-decorator="['mur_url', {rules: [{type: 'url', message: 'URL není ve správném formátu!'}, {max: 200, message: 'URL nemůže být delší než 200 znaků!'}]}]"/>
        </a-form-item>

        <my-form-image-item
          title="Logo"
          subject="Výrobce"
          decorator-name="existing"
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
        ></my-form-image-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || (thumbImage.switch && !this.isThumbImageSelected(false))">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import imageSizeMixin from '@/components/MyComponents/Gallery/image-size-mixin'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [imageSizeMixin, thumbImageMixin],
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  data() {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/manufacturer',
          title: 'Seznam',
        },
      ],
      item: {
        iae_id: null,
        languages: [],
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('manufacturer/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      const data = new FormData()
      const jsonObject = {
        languages: this.item.languages.filter(x => x.mle_description !== undefined && x.mle_description.trim().length > 0),
        mur_name: this.form.getFieldValue('mur_name'),
        mur_url: this.form.getFieldValue('mur_url') === undefined || this.form.getFieldValue('mur_url').trim().length === 0 ? null : this.form.getFieldValue('mur_url'),
        iae_id: !this.isThumbImageSelected(false) ? null : this.thumbImage.selectedOne.iae_id,
      }
      data.append('json', JSON.stringify(jsonObject))
      return data
    },
    handleChange(oldLanguage) {
      const foundObj = { manufacturer: false }
      const newObj = {
        manufacturer: {
          lge_id: oldLanguage,
          mle_description: this.form.getFieldValue('mle_description'),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.manufacturer
          foundObj.manufacturer = true
          return true
        }
      })
      if (!foundObj.manufacturer) {
        this.item.languages.push(newObj.manufacturer)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { manufacturer: this.item.languages.find(x => x.lge_id === newValue) }
        const newObj = foundObj.manufacturer === undefined ? { mle_description: undefined } : { mle_description: foundObj.manufacturer.mle_description }
        this.form.setFieldsValue(newObj)
        this.form.validateFields()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
